@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vollkorn:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Eczar:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Petrona:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.coming-soon {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.coming-soon__background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.coming-soon__text {
  position: absolute;
  bottom: 6%;
  right: 6%;
  -webkit-user-select: none;
          user-select: none;
  font-family: 'Cormorant Garamond', serif;
  font-size: 3.5rem;
  font-weight: 700;
  text-align: right;
  color: #f9e8d0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 10;

  @media (max-width: 768px) {
    font-size: 2.2rem;
    bottom: 0%;
  }
}
